<template>
  <div>
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Form class="search-form"
            :label-width="85"
           >
        <Form-item label="报告时间">
          <Date-picker
            format="yyyy-MM-dd"
            type="daterange"
            placeholder="选择日期"
            @on-change="handleChangeDate"
            style="width:300px;margin-right:10px;"></Date-picker>
          <Button type="primary" @click="getReportData">搜索报告</Button>
        </Form-item>
        <Form-item label="选择对象">
          <Row v-if="!branchShow">
            <Col span="5">
              <span class="sub-label">省份：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="reportData.pid"
                        placeholder="请选择..."
                        @on-change="changePro">
                  <Option :value="v.id"
                          v-for="v in proList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">城市：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="reportData.cid"
                        placeholder="请选择..."
                        @on-change="changeCity">
                  <Option :value="v.id"
                          v-for="v in cityList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">门店：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="reportData.sid"
                        placeholder="请选择..."
                        @on-change="changeStore">
                  <Option :value="v.id"
                          v-for="v in storeList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">会员：</span>
              <div class="sub-content">
                <Select clearable
                  v-model="uid"
                  placeholder="请选择..."
                  filterable
                  @on-change="handleMemberChange">
                  <Option :value="v.id"
                          v-for="v in userList"
                          :key="v.id">{{v.true_name}}</Option>
                </Select>
              </div>
            </Col>
          </Row>
          <Row v-else>
            <Col span="5">
              <span class="sub-label">省份：</span>
                <div class="sub-content">
                  <Input disabled v-model="proName"></Input>
                </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">城市：</span>
              <div class="sub-content">
                <Input disabled v-model="cityName"></Input>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">门店：</span>
              <div class="sub-content">
                <Input disabled v-model="sName"></Input>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">会员：</span>
              <div class="sub-content">
                <Select clearable
                  v-model="uid"
                  placeholder="请选择..."
                  filterable
                  @on-change="handleMemberChange">
                  <Option :value="v.id"
                          v-for="v in userList"
                          :key="v.id">{{v.true_name}}</Option>
                </Select>
              </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="报告条件">
					<Row>
						<Col span="10">
              <span class="sub-label">血压数据：</span>
              <div class="sub-content">
                <Select placeholder="选择日期" @on-change="changePressureDate" filterable>
                  <Option :value="v.rq" v-for="v in availableDate" :key="v.rq">{{v.name}}</Option>
                </Select>
              </div>
						</Col>
						<Col span="10" offset="1">
              <span class="sub-label">血压日期：</span>
              <div class="sub-content">
                <Date-picker format="yyyy-MM-dd" type="date" placeholder="选择日期" @on-change="changePressureDate"
                style="width:200px;"></Date-picker>
              </div>
						</Col>
					</Row>
				</Form-item>
        <Form-item label="">
					<Button @click="generateReport" style="margin-right: 4px;">生成报告</Button>
					<Button @click="saveAsPDF">存为PDF</Button>
				</Form-item>
      </Form>
    </div>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom">血压管理报告</h2>
      <div class="report-body">
        <Table :columns="reportColumns" :data="reportList" :height="220"></Table>
        <div>
          <span class="records">共 {{reportTotal}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="reportTotal"
              :current="reportCurrentPage"
              :page-size="reportData.size"
              @on-change="changeReportPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">
        <span>数据结果</span>
        <Button type="primary" @click="showModal = true" v-show="pressureDataCopy.length">补录数据</Button>
      </h2>
      <template v-if="pressureDataCopy.length">
        <pressure-one :pressure-data="pressureData" :pressure-summary="pressureSummary" />
        <pressure-two :pressure-data="pressureData" :result="reportResult" />
      </template>
    </div>
    <Modal
      v-model="showModal"
      title="补录数据"
      class-name="vertical-center-modal"
      width="800"
      @on-ok="generateReport">
      <table class="modal-table">
        <tr>
          <th>监测时间点</th>
          <th style="width: 15%;">收缩压</th>
          <th style="width: 15%;">舒张压</th>
          <th style="width: 15%;">心率</th>
          <th style="width: 30%;">测量前行为</th>
          <th style="width: 10%;">操作</th>
        </tr>
        <tr v-for="(item, index) in pressureDataCopy" :key="index">
          <td>
            <TimePicker
              type="time"
              format="HH:mm"
              placeholder="请选择时间"
              style="width: 100%;"
              :value="item.create_time"
              @on-change="handleModalDateChange($event, index)"></TimePicker>
          </td>
          <td><InputNumber :active-change="false" :precision="0" v-model="item.systolic" placeholder="请输入"></InputNumber></td>
          <td><InputNumber :active-change="false" :precision="0" v-model="item.diastolic" placeholder="请输入"></InputNumber></td>
          <td><InputNumber :active-change="false" :precision="0" v-model="item.heart" placeholder="请输入"></InputNumber></td>
          <td>
            <CheckboxGroup v-model="item.behavior">
              <Checkbox label="不运动" :disabled="item.behavior.indexOf('运动') > -1"></Checkbox>
              <Checkbox label="运动" :disabled="item.behavior.indexOf('不运动') > -1"></Checkbox>
              <Checkbox label="吃药"></Checkbox>
            </CheckboxGroup>
          </td>
          <td><Button size="small" @click="resetData(index)">重置</Button></td>
        </tr>
      </table>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import comparisonService from '@/services/comparisonService';
import qualityService from '@/services/qualityService';
import memberService from '@/services/memberService';
import reportSportsService from '@/services/reportSportsService';
import dayjs from 'dayjs';
import PressureOne from './pressure-1';
import PressureTwo from './pressure-2';
import { mapActions } from 'vuex';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
	components: { PressureOne, PressureTwo },
	data() {
		return {
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			uid: '',
			userInfo: {},
			availableDate: [],
			reportColumns: [
				{ title: '门店', key: 'md', align: 'center' },
				{ title: '顾客姓名', key: 'gkmc', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '出具日期', key: 'cjrq', align: 'center' },
				{ title: '检测师', key: 'jcs', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const url = params.row.url;
						return h('div', [
							h('a', {
								class: 'icon_eye',
								attrs: {
									href: url,
									target: '_blank',
								},
							}),
							h('Icon', {
								attrs: {
									type: 'md-arrow-round-down',
									size: 22,
									color: '#fdad00',
								},
								style: { cursor: 'pointer' },
								on: {
									click: () => {
										const a = document.createElement('a');
										a.href = url;
										a.style.display = 'none';
										a.setAttribute('download', decodeURI(url.substring(url.lastIndexOf('/') + 1)));
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
									},
								},
							}),
						]);
					},
				},
			],
			reportList: [],
			reportTotal: 0,
			reportCurrentPage: 1,
			reportData: {
				type: 1,
				month_flag: 0,
				pic_type: 40,
				page: 1,
				size: 30,
				pid: '',
				cid: '',
				sid: '',
			},
			pressureData: [],
			pressureDataCopy: [],
			pressureSummary: {},
			showModal: false,
			reportResult: {},
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.reportData.sid = this.branch_id[2];
					this.reportData.cid = this.branch_id[1];
					this.reportData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.getReportData();
	},
	methods: {
		...mapActions(['setReportUserData']),
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.reportData.pid];
			this.reportData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.reportData.cid];
			this.reportData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			this.uid = '';
			if (this.reportData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.reportData.pid,
					cid: this.reportData.cid,
					sid: this.reportData.sid,
					simple_query: 1,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		handleChangeDate(value) {
			this.reportData.start_time = value[0];
			this.reportData.end_time = value[1];
		},
		getReportData() {
			if (this.uid) {
				this.reportData.member_id = this.uid;
			}
			memberService.getReportList(this.reportData).then((data) => {
				this.reportList = data.list;
				this.reportTotal = data.row_size;
				this.reportCurrentPage = +data.cur_page;
			});
		},
		handleMemberChange() {
			reportSportsService.getPressureDate({ member_id: this.uid }).then((data) => {
				this.availableDate = data;
			});
			reportSportsService.getHeadInfo({ member_id: this.uid }).then((data) => {
				this.userInfo = data;
				this.setReportUserData(data);
			});
		},
		changeReportPage(page) {
			this.pressureParams.page = page;
		},
		generateReport() {
			if (!this.uid) {
				this.$Message.error('请选择会员');
				return;
			}
			this.pressureDataCopy.forEach((item) => {
				item.diff = item.systolic - item.diastolic;
			});
			const length = this.pressureDataCopy.length;
			const systolic = this.pressureDataCopy.map((item) => item.systolic); // 收缩压
			const diastolic = this.pressureDataCopy.map((item) => item.diastolic); // 舒张压
			const heart = this.pressureDataCopy.map((item) => item.heart); // 心率
			const overTimes = this.pressureDataCopy.filter((item) => item.systolic > 139).length;
			const avgPressure = `${Math.round(systolic.reduce((cur, prev) => cur + prev, 0) / length)}/${Math.round(diastolic.reduce((cur, prev) => cur + prev, 0) / length)}`;
			const avgDiff = `${Math.round(this.pressureDataCopy.map((item) => item.diff).reduce((cur, prev) => cur + prev, 0) / length)}`;
			const waveSystolic = `${Math.max(...systolic) - Math.min(...systolic)}`;
			const waveDiastolic = `${Math.max(...diastolic) - Math.min(...diastolic)}`;
			const avgHeart = `${Math.round(heart.reduce((cur, prev) => cur + prev, 0) / length)}`;
			const waveHeart = `${Math.max(...heart) - Math.min(...heart)}`;
			this.pressureSummary = {
				overTimes,
				avgPressure,
				avgDiff,
				waveSystolic,
				waveDiastolic,
				avgHeart,
				waveHeart,
			};
			reportSportsService
				.createPressureReport({
					member_id: this.uid,
					data: JSON.stringify({
						pressureData: this.pressureDataCopy,
						pressureSummary: this.pressureSummary,
					}),
				})
				.then((data) => {
					this.pressureData = [...this.pressureDataCopy];
					this.reportResult = data;
				});
		},
		saveAsPDF() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			setTimeout(() => {
				const printTarget = document.querySelectorAll('.quantify-container');
				if (printTarget.length > 0) {
					const self = this;
					const pdf = new JsPDF({ compress: true, unit: 'pt' });
					Promise.all(
						Array.from(printTarget).map((item) =>
							html2canvas(item, {
								scale: 1,
								scrollX: 0,
								scrollY: -window.scrollY,
							}),
						),
					).then((canvasList) => {
						for (let i = 0; i < canvasList.length; i++) {
							const canvas = canvasList[i];
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							const pageData = canvas.toDataURL('image/png', 1);

							if (i !== 0) pdf.addPage();
							pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);

							if (i === printTarget.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.userInfo.name}${time}13+1血压及血管风险管理.pdf`;
								const formData = new FormData();
								formData.append('photo', pdf.output('blob'));
								formData.append('check', 1);
								formData.append('name', fileName);
								reportSportsService
									.createPressureReport({
										member_id: this.uid,
										data: JSON.stringify({
											pressureData: this.pressureData,
											pressureSummary: this.pressureSummary,
										}),
										finish: 1,
									})
									.then(() => {
										memberService.uploadReport(formData).then((data) => {
											memberService
												.addReport({
													member_id: self.userInfo.member_id,
													type: 1,
													bgzl: 40,
													check_date: data.check_date,
													photo_id: data.id,
													bgms: '出具报告自动上传',
												})
												.then(() => {
													self.$Message.success('转存成功');
												})
												.catch(() => {
													self.$Message.success('转存失败，请手动上传');
												});
										});
									});

								pdf.save(fileName);
								self.$nextTick(() => {
									loading.close();
								});
							}
						}
					});
				} else {
					this.$Message.warning('无法生成PDF，请检查报告是否存在');
				}
			}, 0);
		},
		changePressureDate(value) {
			if (!value) {
				this.pressureDataCopy = 0;
				return;
			}
			reportSportsService.getPressureData({ member_id: this.uid, day: value }).then((data) => {
				const drop = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
				this.pressureDataCopy = Object.assign(drop, data).map((item) => {
					item.systolic = item.systolic || null;
					item.diastolic = item.diastolic || null;
					item.heart = item.heart || null;
					item.behavior = ['不运动'];
					return item;
				});
			});
		},
		handleModalDateChange(e, index) {
			this.pressureDataCopy[index].create_time = e;
		},
		resetData(index) {
			const temp = [...this.pressureDataCopy];
			temp.splice(index, 1, {
				systolic: null,
				diastolic: null,
				heart: null,
				behavior: ['不运动'],
				create_time: null,
			});
			this.pressureDataCopy = temp;
		},
	},
};
</script>

<style lang="less" scoped>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 75px;
}

.table {
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
  text-align: center;
  th {
    background-color: #0062C3;
    color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
  }
  td {
    color: #0062C3;
    padding: 5px;
    height: 40px;
    font-weight: bold;
    border: 1px solid #eee;
  }
}

.report-body {
  margin: 10px;
}

.modal-table {
  font-size: 16px;
  th, td {
    border: #266dd9 solid 1px;
  }
  th {
    padding: 5px;
    color: #266dd9;
  }
  tr:first-child {
    background-color: #3569b9;
    th, td {
      color: #fff;
    }
  }
  width: 100%;
  text-align: center;
}
</style>
